import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const HeaderContainer = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  // padding:1rem;
  background: transparent;
  @media (max-width: 800px) {
    justify-content: flex-start;
    flex-direction:row;
  }
`;
  export const LogoSection = styled(FlexibleDiv)`
    justify-content: flex-start;
    width: auto;
    align-items: center;
    .Link {
      display: flex;
      align-items: center;
      text-decoration: none;
      width: 100%;
      color: #fff;
      cursor: pointer;
    }
  `;
export const LogoImage= styled.img`
width:auto;
height:auto;
`;
export const LogoText = styled.p`
  font-size: 1rem;
  font-weight: 900;
  padding: 0;
  margin: 0 0 0 0.6rem;
  @media (max-width: 800px) {
    font-size: 0.9rem;
  }
`;
