import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";

export const ValidateContainer = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  padding-top: 7rem;
  background: #000091;
  @media (max-width: 800px) {
    padding-top: 4rem;
  }
`;

export const TextContainer = styled(FlexibleDiv)`
  flex-direction: column;
  align-items: center;
  height: auto;

`;
export const H3 = styled.h3`
  font-size: 3.5rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: segioBold;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 2.8rem;
  }
`;
export const H5 = styled.h5`
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;
export const WalletContainer = styled(FlexibleDiv)`
  background: #fff 0 0 no-repeat padding-box;
  border: thin #707070;
  border-radius: 36px;
  opacity: 1;
  height: 100%;
  @media (max-width: 800px) {
   flex-direction:row;
  }
`;

export const WalletCard = styled(FlexibleDiv)`
 flex-direction:column;
 align-items:center;
 justify-content:space-between;
height:160px;
 width:22%;
cursor:pointer;
`;
export const Wrapper = styled(FlexibleDiv)`
 justify-content:center;
 align-items:center;
 padding:0;

`;
export const WalletTypeImage = styled.img`
height:90%;
`;
export const WalletTypeName = styled.p`
  color: #6f859f;
  margin:0;
  padding:0;
`;
