import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";

export const CardContainer = styled(FlexibleDiv)`
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0 3px 16px #00000014;
  border-radius: 47px;
  width: 30%;
  //   max-width:200px;
  min-height: 350px;
  height: auto;
  padding: 1rem;
  @media (max-width: 800px) {
    width: 90%;
    margin-bottom: 5vh;
    padding: 1rem 1rem;
  }
`;

export const Image = styled.img`
  width: 130px;
  height: auto;
`;
export const Text = styled.p`
  font-size: 1rem;
  color: #6f859f;
  //   margin-bottom: 2rem;
  text-align: center;
  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;
