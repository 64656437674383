import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  // Link
} from "react-router-dom";
import Home from "../../Pages/Home/Home";
import Validate from "../../Pages/Validate/Validate";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/validate" element={<Validate />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
