import React from "react";
import {
  HowToValidateContainer,
  SectionContainer,
  TitleText,
  SubText,
  CardSection,
  ButtonContainer,
} from "./HowToValidate.styles";
import firstImg from "../../../../assets/images/chain-agnostic.png";
import secondImg from "../../../../assets/images/multi-chain.png";
import thirdImg from '../../../../assets/images/multi-session.png';
import { Button } from "../../../../components/button/button.component";
import { Card } from "../../../../components/Card/card";



export const HowToValidate =()=>{
    const data = [
      {
        image: firstImg,
        text: "This approach guarantees that the token is valid, not expired or revoked. Every backend service validates token only by signature. Some services that have strong security requirement (in case of personal data access) may still validate access token by the introspecting endpoint.",
      },
      {
        image: secondImg,
        text: "The Validate Token method is called by the infrastructure to validate nd extract the claims from the decentralized security token. These claims are returned in the collection of Claims Identity objects returned by the method. In the typical case, this collection will contain a single identity.",
      },
      {
        image: thirdImg,
        text: "By specifying a key here, the token can be validated without any need for the issuing server. What is needed, instead is the location of the public key. The certLocation parameter in the sample above is a string pointing to a certificate file containing the public key corresponding to the private key used by the issuing authenticating server.",
      },
    ];
    return (
      <HowToValidateContainer>
        <SectionContainer paddingVertical="0">
          <TitleText>How do we validate your</TitleText>
          <SubText>Tokens?</SubText>
          <CardSection>
              {data.map(({image,text})=>(
                  <Card image={image} text={text} /> 
              ))}
          </CardSection>
          <ButtonContainer>
          <Button
            btnText="Validate Now"
            //   onClick,
          />
              </ButtonContainer>
        </SectionContainer>
      </HowToValidateContainer>
    );
};