import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

export const WhatAreCryptoTokenContainer = styled(FlexibleDiv)`
  justify-content: space-around;
 
`;
export const LeftSection = styled(FlexibleDiv)`
  width: 40%;
  align-items: center;
  justify-content: center;
  height: auto;
  @media (max-width: 800px) {
    width: 90%;
  }
`;
export const RightSection = styled(FlexibleDiv)`
  width: 40%;
  flex-direction: column;
  align-items: flex-end;
  height: auto;
  @media (max-width: 800px) {
    width: 90%;
    align-items: center;
  }
`;
export const TitleText = styled.p`
  font-size: 3rem;
  color: #6f859f;
  padding: 0;
  margin-bottom: 1rem;
  text-align: right;
  @media (max-width: 800px) {
    font-size:2.7rem;
    text-align: center;
  }
`;
export const SubText = styled.h5`
  font-size: 2.5rem;
  color: #8408fe;
  margin-bottom: 2rem;
  font-family: segioBold;
  text-align: right;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
export const Text = styled.p`
  font-size: 1.2rem;
  color: #6f859f;
  margin-bottom: 2rem;
  text-align: right;
  @media (max-width: 800px) {
    text-align: center;
    font-size: 1.1rem;
  }
`;
export const HeroImage = styled.img`
  width: auto;
  max-width: 90%;
  @media (max-width: 800px) {
    max-width: 70%;
  }
`;
