import styled from "styled-components";
import { colors } from "../../infrastructure/theme/colors.js";
import { Text } from "../typography/text.component.js";
import { Link } from "react-router-dom";


export const LinkWrapper = styled(Link)`
  width: 100%;
  cursor: pointer;
  text-decoration:none;
`;


export const ButtonStyles = styled.button`
  cursor: pointer;
  width: 100%;
  background: ${({ background }) =>
    background
      ? background
      : `linear-gradient(0deg,#4f24ff,#13d9e2) 0 0 no-repeat ;padding-box`};
  border: ${({ border }) => (border ? border : "0px")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "32px"};
  padding: ${({ size }) => (size ? "0.8rem" : "0.3rem")} 0;
  color: ${({ btnColor }) => (btnColor ? btnColor : colors.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 0;
  height: 64px;
`;
export const BtnText = styled(Text)`
  padding: 0 0.5rem;
  color: ${({ btnColor }) => (btnColor ? btnColor : "#fff")};
  text-transform: ${({ textTransform }) =>
    textTransform ? textTransform : "capitalize"};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "1.5rem")};
`;

export const Button = ({
  btnText,
  background,
  border,
  borderRadius,
  btnColor,
  textTransform,
  fontWeight,
  fontSize,
  onClick,
  size,
}) => {
  return (
    <LinkWrapper to="validate">
      <ButtonStyles
        background={background}
        border={border}
        borderRadius={borderRadius}
        fontSize={fontSize}
        onClick={onClick ? onClick : null}
        size={size}
      >
        <BtnText
          btnColor={btnColor}
          textTransform={textTransform}
          fontWeight={fontWeight}
          fontSize={fontSize}
        >
          {btnText}
        </BtnText>
      </ButtonStyles>
    </LinkWrapper>
  );
};
