import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

export const FooterContainer = styled(FlexibleDiv)`
  justify-content: space-around;
  background: #6f859f;
`;

export const SectionContainer = styled(FlexibleDiv)`
  flex-direction: column;
  align-items: center;
  height: auto;
`;

export const CardSection = styled(FlexibleDiv)`
  justify-content: space-around;
  align-items: center;
  height: auto;
`;
export const TitleText = styled.p`
  font-size: 1.7rem;
  color: #fff;
  padding: 0;
  text-align: center;
 
`;
export const ButtonContainer = styled(FlexibleDiv)`
  width: 35%;
  align-items: center;
  justify-content: center;
  height: auto;
  @media (max-width: 800px) {
    width:90%;
  }
`;
export const SubText = styled.h5`
  font-size: 3rem;
  color: #8408fe;
  font-family: segioBold;
  text-align: right;
  @media (max-width: 800px) {
    font-size: 2.7rem;
  }
`;

export const BottomSection = styled(FlexibleDiv)`
  align-items: center;
  justify-content: center;
  height: auto;
`;
export const Text = styled.p`
  font-size: 0.8rem;
  color: #fff;
  @media (max-width: 800px) {
    font-size: 1.3rem;
  }
`;
export const Spacer = styled.div`
 padding:1rem;
`;