import React from "react";
import { ToastMessageContainer, Text } from "./toastMessage.styles";

export const ToastMessage = ({ message, activate }) => {
  return (
    <ToastMessageContainer activate={activate}>
      <Text>{message}</Text>
    </ToastMessageContainer>
  );
};
