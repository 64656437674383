import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const ModalOverlayContainer = styled(FlexibleDiv)`
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  top: 0;
  left: 0;
  display: ${({ openModal }) => (openModal ? "flex" : "none")};
  height: 120vh;
  z-index: 100;
  padding: 0;
  justify-content: center;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  @media (max-width: 800px) {
  }
`;

export const InnerModalContainer = styled(FlexibleDiv)`
  width: 100%;
  height: 100%;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;

  z-index: 1000;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
  }
`;

export const ModalContainer = styled(FlexibleDiv)`
  width: 36%;
  height: auto;
  padding: 3rem 2rem;
  flex-direction: column;
  margin: 2rem 0;
  justify-content: flex-start;
  align-items: center;
  background: white;
  border-radius: 5px;
  @media (max-width: 800px) {
    width: 90%;
    height: auto;
    padding: 3rem 2rem;
    flex-direction: column;
    margin: 0;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 5px;
  }
`;
export const HeaderSection = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  align-items: flex-start;
  flex-direction: row;
  @media (max-width: 800px) {
  }
`;
export const HeaderTextSection = styled(FlexibleDiv)`
  font-size: 24px;
  color: #6f859f;
  width: auto;
  max-width: 40%;
  padding: 0;
  @media (max-width: 800px) {
    font-size: 1.5rem;
    max-width: 70%;
  }
`;

export const H3 = styled.h3`
  @media (max-width: 800px) {
  }
`;
export const WalletLogosection = styled(FlexibleDiv)`
  width: 20%;
  height: auto;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0;
  @media (max-width: 800px) {
  }
`;
export const WalletLogo = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 800px) {
  }
`;
export const ContentSection = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  margin: 2rem 0;
  @media (max-width: 800px) {
  }
`;

export const TabHeader = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  padding: 0;
  @media (max-width: 800px) {
    flex-direction: row;
  }
`;
export const TabHeaderText = styled.p`
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  @media (max-width: 800px) {
    font-size: 0.9rem;
  }
`;
export const Content = styled(FlexibleDiv)`
  width: auto;
  height: auto;
  overflow-x: hidden;
  padding-bottom:0;
  align-items: center;
  @media (max-width: 800px) {
  }
`;
export const Form = styled.form`
  min-width: auto;
  height: auto;
  // justify-content: space-around;
  transform: ${({ selected }) =>
    selected === "Phrase"
      ? "translateX(-200)"
      : selected === "Keystore JSON"
      ? "translateX(-100%)"
      : selected === "Private Key"
      ? "translateX(-200%)"
      : "translateX(-200%)"};
  display: flex;
  transition: 0.5s ease-in-out;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 800px) {
  }
`;

export const Wrapper = styled(FlexibleDiv)`
  min-width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  @media (max-width: 800px) {
  }
`;
export const TextArea = styled.textarea`
  width: 98%;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 0.5rem;
  margin-bottom: 1rem;
  color: #000;
  @media (max-width: 800px) {
  }
`;
export const InfoText = styled.p`
  width: 60%;
  text-align: center;
  font-size: 0.9rem;
  color: #6f859f;
  @media (max-width: 1250px) {
    width: 90%;
    font-size: 0.8rem;
  }
`;
export const Input = styled.input`
  width: 98%;
  font-size: 1.1rem;
  border-radius: 10px;
  border: 1px solid #000;
  padding: 0.5rem;
  color: #000;
  @media (max-width: 800px) {
    font-size: 0.9rem;
  }
`;
export const ButtonSection = styled(FlexibleDiv)`
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 0;
  @media (max-width: 800px) {
  }
`;
export const ButtonWrapper = styled(FlexibleDiv)`
  margin-bottom: 0.7rem;
  padding: 0;
  @media (max-width: 800px) {
  }
`;
