import React, { useState,useEffect } from "react";
import {
  ValidateContainer,
  TextContainer,
  H3,
  H5,
  WalletContainer,
  WalletCard,
  Wrapper,
  WalletTypeImage,
  WalletTypeName,
} from "./Validate.styles";
import { WalletData } from "../Utils/data";
import { Modal } from "../../components/Modal/Modal";

const Validate = () => {
  const [openModal, setOpenModal] = useState(false);
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const handleModal = (image, name) => {
    return setOpenModal(!openModal);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ValidateContainer paddingVertical="0">
      <Modal
        logo={image}
        name={name}
        openModal={openModal}
        handleModal={handleModal}
      />
      <TextContainer boxedLayout>
        <H3>Wallets</H3>
        <H5>
          Multiple iOS and Android wallets support the protocol. Simply scan a
          QR code from your desktop computer screen to start securely using a
          dApp with your mobile wallet, interaction between mobile apps and
          mobile browsers are supported via mobile deep linking.
        </H5>
      </TextContainer>
      <WalletContainer>
        {WalletData.map(({ image, name }) => (
          <WalletCard
            onClick={() => {
              handleModal(image, name);
              setImage(image);
              setName(name);
            }}
          >
            <Wrapper height="70%">
              <WalletTypeImage src={image} alt="" />
            </Wrapper>
            <Wrapper height="20%">
              <WalletTypeName>{name}</WalletTypeName>
            </Wrapper>
          </WalletCard>
        ))}
      </WalletContainer>
    </ValidateContainer>
  );
};

export default Validate;
