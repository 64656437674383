import React from "react";
import {
  FooterContainer,
  SectionContainer,
  TitleText,
  CardSection,
  ButtonContainer,
  Text,
  BottomSection,
  Spacer,
} from "./Footer.styles";
import { Button } from "../../../../components/button/button.component";

export const Footer =()=>{
    return (
      <FooterContainer>
        <SectionContainer paddingVertical="0">
          <TitleText>
            You can also enable wallet connect for your multiple iOS and Android
            wallets protocol
          </TitleText>
          <CardSection>
            <ButtonContainer>
              <Button
                btnText="Recovery"
                background="#e9ecef"
                btnColor="#6f859f"
                //   onClick,
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                btnText="Rectification"
                background="#e9ecef"
                btnColor="#6f859f"
                //   onClick,
              />
            </ButtonContainer>
          </CardSection>
          <BottomSection paddingVertical="0">
            <Text>Discord</Text>
            <Spacer />
            <Text>Twitter</Text>
          </BottomSection>
        </SectionContainer>
      </FooterContainer>
    );
};