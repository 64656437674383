import React from "react";
import { Header } from "../../components/Header/header";
import { HomeContainer, GradientBg } from "./Home.styles";
import { Footer } from "./Sections/Footer/Footer";
import { HeroSection } from "./Sections/HeroSection/HeroSection";
import { HowToValidate } from "./Sections/HowToValidate/HowToValidate";
import { WhatAreCryptoToken } from "./Sections/WhatAreCryptoToken/WhatAreCryptoToken";

const Home = () => {
  return (
    <HomeContainer paddingVertical="0">
      <GradientBg >
        <Header />
        <HeroSection />
      </GradientBg>
      <WhatAreCryptoToken />
      <HowToValidate />
      <Footer />
    </HomeContainer>
  );
};

export default Home;
