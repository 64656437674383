import styled from "styled-components";
import { FlexibleDiv } from "../../components/flexibleDiv/flexibleDiv.component";

export const HomeContainer= styled(FlexibleDiv)`

`;


export const GradientBg = styled(FlexibleDiv)`
  background: linear-gradient(
    90deg,
    #5209c6,
    #ff5481 38%,
    #ff5481 76%,
    #c609c1
  );

  @media (max-width: 800px) {
  padding-top:0.7rem;
  }
`;



