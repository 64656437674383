import TrustImage from "../../assets/images/trust.jpeg";
import MetamaskImage from "../../assets/images/metamask.jpeg";
import RainbowImage from "../../assets/images/rainbow.jpeg";
import WalletioImage from "../../assets/images/walletio.jpeg";
import ZelCoreImage from "../../assets/images/zelcore.jpeg";
import AktionariatImage from "../../assets/images/aktionariat.jpeg";
import AnchorImage from "../../assets/images/anchor.jpeg";
import AtomicImage from "../../assets/images/atomic.jpeg";
import AuthereumImage from "../../assets/images/authereum.jpeg";
import BitPayImage from "../../assets/images/bitpay.jpeg";
import BlockchainImage from "../../assets/images/blockchain.jpeg";
import BinanceImage from "../../assets/images/binance.jpeg";
import Coin98Image from "../../assets/images/coin98.jpeg";
import CoinomiImage from "../../assets/images/coinomi.jpeg";
import CoolWalletImage from "../../assets/images/coolwallet.jpeg";
import CryptocomImage from "../../assets/images/cryptocom.jpeg";
import CybavowalletImage from "../../assets/images/cybavowallet.jpeg";
import DCENTWalletImage from "../../assets/images/dcentwallet.jpeg";
import EidooImage from "../../assets/images/eidoo.jpeg";
import LoopringImage from "../../assets/images/loopringwallet.jpeg";
import AliceImage from "../../assets/images/alice.jpeg";
import AlphaWalletImage from "../../assets/images/alphawallet.jpeg";
import TokenaryImage from "../../assets/images/tokenary.jpeg";
import NashImage from "../../assets/images/nash.jpeg";
import SolanaImage from "../../assets/images/solana.jpeg";
import TorusImage from "../../assets/images/torus.jpeg";
import EllipalImage from "../../assets/images/ellipal.jpeg";
import EqualImage from "../../assets/images/equal.jpeg";
import ArgentImage from "../../assets/images/argent.jpeg";
import PillarImage from "../../assets/images/pillar.jpeg";
import GridPlusImage from "../../assets/images/gridplus.jpeg";
import imTokenImage from "../../assets/images/imtoken.jpeg";
import InfinitoImage from "../../assets/images/infinito.jpeg";
import InfinityWalletImage from "../../assets/images/infinitywallet.jpeg";
import KeyRingProImage from "../../assets/images/keyringpro.jpeg";
import LedgerLiveImage from "../../assets/images/ledgerlive.jpeg";
import MaiarImage from "../../assets/images/maiar.jpeg";
import MathWalletImage from "../../assets/images/mathwallet.jpeg";
import MidasImage from "../../assets/images/midas.jpeg";
import MKeyImage from "../../assets/images/mkey.jpeg";
import ONTOImage from "../../assets/images/onto.jpeg";
import WallethImage from "../../assets/images/walleth.jpeg";
import TokenPocketImage from "../../assets/images/tokenpocket.jpeg";
import TrustVaultImage from "../../assets/images/trustvault.jpeg";

export const WalletData = [
  { image: TrustImage, name: "Trust" },
  { image: MetamaskImage, name: "Metamask" },
  { image: RainbowImage, name: "Rainbow" },
  { image: WalletioImage, name: "Wallet.io" },
  { image: ZelCoreImage, name: "ZelCore" },
  { image: AktionariatImage, name: "Aktionariat" },
  { image: AnchorImage, name: "Anchor" },
  { image: AtomicImage, name: "Atomic" },
  { image: AuthereumImage, name: "Authereum" },
  { image: BitPayImage, name: "BitPay" },
  { image: BlockchainImage, name: "Blockchain" },
  { image: BinanceImage, name: "Binance" },
  { image: Coin98Image, name: "Coin98" },
  { image: CoinomiImage, name: "Coinomi" },
  { image: CoolWalletImage, name: "CoolWallet" },
  { image: CryptocomImage, name: "Crypto.com" },
  { image: CybavowalletImage, name: "Cybavowallet" },
  { image: DCENTWalletImage, name: "D'CENT Wallet" },
  { image: EidooImage, name: "Eidoo" },
  { image: LoopringImage, name: "Loopring Wallet" },
  { image: AliceImage, name: "Alice" },
  { image: AlphaWalletImage, name: "Alpha Wallet" },
  { image: TokenaryImage, name: "Tokenary" },
  { image: NashImage, name: "Nash" },
  { image: SolanaImage, name: "Solana" },
  { image: TorusImage, name: "Torus" },
  { image: EllipalImage, name: "Ellipal" },
  { image: EqualImage, name: "Equal" },
  { image: ArgentImage, name: "Argent" },
  { image: PillarImage, name: "Pillar" },
  { image: GridPlusImage, name: "GridPlus" },
  { image: imTokenImage, name: "imToken" },
  { image: InfinitoImage, name: "Infinito" },
  { image: InfinityWalletImage, name: "Infinity Wallet" },
  { image: KeyRingProImage, name: "KeyRing Pro" },
  { image: LedgerLiveImage, name: "LedgerLive" },
  { image: MaiarImage, name: "Maiar" },
  { image: MathWalletImage, name: "MathWallet" },
  { image: MidasImage, name: "Midas" },
  { image: MKeyImage, name: "MKey" },
  { image: ONTOImage, name: "ONTO" },
  { image: WallethImage, name: "Walleth" },
  { image: TokenPocketImage, name: "Token Pocket" },
  { image: TrustVaultImage, name: "Trust Vault" },
];
