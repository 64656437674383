import styled from "styled-components";
import { FlexibleDiv } from "../../../../components/flexibleDiv/flexibleDiv.component";

export const HeroSectionContainer = styled(FlexibleDiv)`
  justify-content: space-around;
 
`;
export const LeftSection = styled(FlexibleDiv)`
  width: 40%;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  @media (max-width: 800px) {
    width: 100%;
    align-items: center;
    margin-top: 10vh;
  }
`;
export const RightSection = styled(FlexibleDiv)`
  width: 40%;
  justify-content: center;
  align-items: center;
  height: auto;
  @media (max-width: 800px) {
    width: 100%;
 
  }
`;
export const TitleText = styled.h3`
  font-size: 3rem;
  font-weight: 900;
  color: #fff;
  margin-bottom: 1.5rem;
  font-family: segioBold;
  @media (max-width: 800px) {
    text-align:center;
    font-size: 2.7rem;
  }
`;
export const SubText = styled.h5`
  font-size: 1.5rem;
  color: #fff;
  width: 80%;
  margin-bottom: 2rem;
  font-family: segioBold;
  @media (max-width: 800px) {
    text-align: center;
  }
`;
export const ButtonContainer = styled(FlexibleDiv)`
  width: 80%;
  align-items: center;
  justify-content: center;
  height: auto;
 
`;
export const HeroImage = styled.img`
  width: auto;
  @media (max-width: 800px) {
    max-width: 70%;
  }
`;
// export const HeroSectionContainer = styled(FlexibleDiv)``;
// export const HeroSectionContainer = styled(FlexibleDiv)``;
// export const HeroSectionContainer = styled(FlexibleDiv)``;
