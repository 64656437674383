import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navigation from './infrastructure/Navigation';
import { init } from "@emailjs/browser";

init("PEKeKZu7Yq7Ac4srp");

ReactDOM.render(
  <React.StrictMode>
    <Navigation/>
  </React.StrictMode>,
  document.getElementById('root')
);

