import React from "react";
import {
  WhatAreCryptoTokenContainer,
  LeftSection,
  RightSection,
  TitleText,
  SubText,
  Text,
  HeroImage,
} from "./WhatAreCryptoToken.styles";
import image from "../../../../assets/images/homeHeroImg2.png";


export const WhatAreCryptoToken =()=>{
    return (
      <WhatAreCryptoTokenContainer>
        <LeftSection paddingVertical="0">
          <HeroImage src={image} alt="heroImage" />
        </LeftSection>
        <RightSection paddingVertical="0">
          <TitleText>What are</TitleText>
          <SubText>Crypto Tokens?</SubText>
          <Text>
            The term crypto tokens refers to a special virtual currency token or
            how cryptocurrencies are denominated. These tokens represent
            fungible and tradable assets or utilities that reside on their own
            blockchains. Crypto tokens are often used to fundraise for crowd
            sales, but they can also be used as a substitute for other things.
            These tokens are usually created, distributed, sold and circulated
            through the standard Initial Coin Offering (ICO) process, which
            involves a crowdfunding exercise to fund the project development.
          </Text>
        </RightSection>
      </WhatAreCryptoTokenContainer>
    );
};