import React from "react";
import {
  HeroSectionContainer,
  LeftSection,
  RightSection,
  TitleText,
  SubText,
  ButtonContainer,
  HeroImage,
} from "./HeroSection.styles";
import image from "../../../../assets/images/homeHeroImg.png";
import { Button } from "../../../../components/button/button.component";
export const HeroSection = () => {
  return (
    <HeroSectionContainer resFlexDirection="column-reverse">
      <LeftSection paddingVertical="0">
        <TitleText>DAPPS Support Token</TitleText>
        <SubText>Open protocol for connecting Wallets to Dapps</SubText>
        <ButtonContainer>
          <Button
            btnText="Get Started"
            //   onClick,
          />
        </ButtonContainer>
      </LeftSection>
      <RightSection paddingVertical="0">
        <HeroImage src={image} alt="heroImage" />
      </RightSection>
    </HeroSectionContainer>
  );
};
