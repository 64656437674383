import React from "react";
import { CardContainer, Image, Text } from "./card.styles";

export const Card = ({ image, text }) => {
  return (
    <CardContainer>
      <Image src={image} alt="" />
      <Text>{text}</Text>
    </CardContainer>
  );
};
